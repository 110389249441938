import { Tabs } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Box from '@/components/Box';
import {
  FilterProps,
  StyledTag,
} from '@/components/SearchRequestFilter/shared';
import { GetFilterCountiesQuery } from '@/generated/graphql';
import { Unpacked } from '@/utils/types';

import {
  containsSelectedRegions,
  innerCity,
  isStockholm,
  outerCity,
  regionIdStockholm,
  regionIdStockholmInner,
} from './util';

const { TabPane } = Tabs;

const Container = styled(Box)`
  border: 1px solid ${({ theme }) => theme.colors.primaryPurple};
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top: none;
  margin-top: -8px !important;
  grid-column: 1 / -1;
`;

const Grid = styled(Box)`
  display: grid;
  grid-gap: 4px;
  grid-template-columns: repeat(auto-fit, minmax(125px, 1fr));
`;
function SelectedRegionsStockholm({
  searchRequestSettings,
  setSearchRequestSettings,
  regions,
}: FilterProps & {
  regions: Unpacked<
    GetFilterCountiesQuery['getRegions']['regions']
  >['children'];
}): JSX.Element {
  const { t } = useTranslation();
  const [activeKey, setActiveKey] = useState<'inner' | 'outer'>('inner');

  return (
    <Container w={'100%'} p={2}>
      <Tabs
        activeKey={activeKey}
        onChange={key => setActiveKey(key as 'inner' | 'outer')}
      >
        <TabPane tab={t('Central Stockholm')} key={'inner'} tabKey={'inner'} />
        <TabPane
          tab={t('Outside central Stockholm')}
          key={'outer'}
          tabKey={'outer'}
        />
      </Tabs>
      <Grid>
        <StyledTag
          $centered
          checked={
            activeKey === 'outer'
              ? outerCity.every(region =>
                  searchRequestSettings.regionIds.includes(region.id),
                )
              : searchRequestSettings.regionIds.includes(regionIdStockholmInner)
          }
          onChange={checked => {
            const { regionIds } = searchRequestSettings;
            let next: string[];

            if (checked) {
              next =
                activeKey === 'outer'
                  ? [
                      ...regionIds.filter(id => id !== regionIdStockholm),
                      ...outerCity
                        .map(region => region.id)
                        .filter(id => !regionIds.includes(id)),
                    ]
                  : [
                      ...regionIds.filter(
                        id =>
                          !innerCity.some(
                            region =>
                              region.id === id || id === regionIdStockholm,
                          ),
                      ),
                      regionIdStockholmInner,
                    ];
            } else {
              next = regionIds.filter(id => {
                if (activeKey === 'outer') {
                  return !outerCity.some(region => region.id === id);
                } else {
                  return id !== regionIdStockholmInner;
                }
              });
            }

            if (
              !regions.map(region => region.id).some(id => next.includes(id))
            ) {
              next = [...next, regionIdStockholm];
            }

            setSearchRequestSettings({
              ...searchRequestSettings,
              regionIds: next,
            });
          }}
        >
          {activeKey === 'inner' ? `Hela innerstaden` : t('Select all')}
        </StyledTag>

        {(activeKey === 'inner' ? innerCity : outerCity).map(({ id, name }) => (
          <StyledTag
            key={id}
            $centered
            checked={searchRequestSettings.regionIds.includes(id)}
            onChange={checked => {
              const { regionIds } = searchRequestSettings;
              let next = checked
                ? [
                    ...regionIds.filter(regionId =>
                      activeKey === 'outer'
                        ? regionId !== regionIdStockholm
                        : regionId !== regionIdStockholmInner &&
                          regionId !== regionIdStockholm,
                    ),
                    id,
                  ]
                : regionIds.filter(t => t !== id);

              if (
                !regions.map(region => region.id).some(id => next.includes(id))
              ) {
                next = [...next, regionIdStockholm];
              }

              setSearchRequestSettings({
                ...searchRequestSettings,
                regionIds: next,
              });
            }}
          >
            {name}
          </StyledTag>
        ))}
      </Grid>
    </Container>
  );
}

export default SelectedRegionsStockholm;
